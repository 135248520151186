/**
 * Created by hao.cheng on 2017/5/3.
 */
import React, { CSSProperties } from 'react'
import { Carousel, Input, Skeleton, Card, Avatar, Select } from 'antd'
import {
  homeRecommends,
  homeAdvList,
  homeRecommendList,
  homeExpertList,
  categoryMore,
  channelList,
} from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'
import { Link } from 'react-router-dom'
import TreeData, { TreeTranslateObj } from '../../utils/TreeData'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import Login from '../pages/Login'
import { hasLogin } from '../../utils'
import liveUnstartImg from '../../style/imgs/live-unstart.png'
import liveStartedImg from '../../style/imgs/live-started.gif'
import liveFinishedImg from '../../style/imgs/live-finished.png'

const { Search } = Input
const InputGroup = Input.Group
const { Option } = Select

interface IDashboardProps extends RouteComponentProps<any> {}
interface IDashboardStates {
  courseList: any[]
  trainList: any[]
  planList: any[]
  advList: any[]
  advSwitchTime: any
  categoryList: any[]
  recommendList: any[]
  channelList: any[]
  expertList: any[]
  loading: boolean
  searchType: any
}

const ROW_COUNT = 5
const ROW_2_LINE_COUNT = 7
const EXPERT_COUNT = 6

class Dashboard extends React.Component<IDashboardProps, IDashboardStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      courseList: [],
      trainList: [],
      planList: [],
      advList: [],
      advSwitchTime: 3,
      categoryList: [],
      recommendList: [],
      channelList: [],
      expertList: [],
      loading: false,
      searchType: 0,
    }
  }
  componentDidMount() {
    this.getData()
    this.getAdvList()
    this.getRecommendList()
    this.getChannelList()
    this.getCategoryList()
    this.getExpertList()
  }

  async getData() {
    const params = {}
    const res = await homeRecommends(params)
    if (res.success) {
      const { courseJson, itemJson, planJson } = res.data[0]
      this.setState({
        courseList: courseJson.slice(0, ROW_COUNT),
        trainList: itemJson.slice(0, ROW_2_LINE_COUNT),
        planList: planJson.slice(0, ROW_2_LINE_COUNT),
      })
    }
  }

  async getAdvList() {
    const params = {}
    const res = await homeAdvList(params)
    if (res.success) {
      const { advertList, advertSetMap } = res.data[0]
      this.setState({
        advList: advertList,
        advSwitchTime: parseInt(advertSetMap.dicValue),
      })
    }
  }

  async getRecommendList() {
    const params = {}
    const res = await homeRecommendList(params)
    if (res.success) {
      this.setState({
        recommendList: res.data.slice(0, ROW_COUNT),
      })
    }
  }

  async getChannelList() {
    const params = {}
    const res = await channelList(params)
    if (res.success) {
      this.setState({
        channelList: res.data.slice(0, ROW_2_LINE_COUNT),
      })
    }
  }

  async getCategoryList() {
    const params = {}
    const res = await categoryMore(params)
    const data = res.data
    const treeProps: TreeTranslateObj = {
      data: data,
      titleAttr: 'categoryName',
      idAttr: 'categoryId',
      pidAttr: 'categoryPId',
      otherAttr: 'categoryPId',
      resultTitleAttr: 'categoryName',
      resultIdAttr: 'categoryId',
      resultChildAttr: 'children',
      resultOtherAttr: 'key',
    }
    const treeData = TreeData(treeProps)
    this.setState({
      categoryList: treeData,
    })
  }

  async getExpertList() {
    const params = {}
    const res = await homeExpertList(params)
    if (res.success) {
      this.setState({
        expertList: res.data.slice(0, EXPERT_COUNT),
      })
    }
  }

  onSearch(value: any) {
    console.log(value)
    let type = ''
    switch (this.state.searchType) {
      case '0':
        type = 'course'
        break
      case '1':
        type = 'train'
        break
      case '2':
        type = 'plan'
        break
      default:
        type = 'course'
        break
    }
    this.props.history.replace('/app/train/list?type=' + type + '&searchText=' + value)
  }

  render() {
    const gridStyle: CSSProperties = {
      textAlign: 'center',
      width: '185px',
      padding: 0,
      marginRight: '15px',
      borderRadius: '2px',
      marginTop: '10px',
    }

    return (
      <div>
        <Skeleton loading={this.state.loading}>
          <Carousel autoplay>
            {this.state.advList.map((adv: any, index: number) => (
              <a target="_blank" href={adv.advertURL}>
                <div className="home_banner">
                  <div
                    className="banner-item w-h-100"
                    style={{
                      backgroundImage: `url(${IMAGE_SERVER + adv.adverDirectURL})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
              </a>
            ))}
          </Carousel>
          <div className="app_layout_main">
            <div className="home-search">
              <div className="flex-vcenter search" style={{ lineHeight: '64px', float: 'right' }}>
                <InputGroup compact size="large" style={{ width: '100%' }}>
                  <Select
                    size="large"
                    defaultValue="0"
                    onChange={(value: any) => {
                      setTimeout(() => {
                        this.setState({
                          searchType: value,
                        })
                      }, 0)
                    }}
                  >
                    <Option value="0">课程</Option>
                    <Option value="1">项目</Option>
                    <Option value="2">计划</Option>
                  </Select>
                  <Search
                    placeholder="请输入想要的内容"
                    enterButton="搜索"
                    type="ghost"
                    size="large"
                    style={{ width: 800 }}
                    onSearch={(value) => this.onSearch(value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="home-category">
              {this.state.categoryList.map((category: any) => (
                <div className="category-item">
                  <Link
                    to={'/app/train/list?type=course&categoryId=' + category.categoryId}
                    color="gray"
                  >
                    <div className="category-item-content">
                      <div className="category-title">{category.categoryName}</div>
                      <div className="category-children">
                        {category.children.length <= 3 &&
                          category.children &&
                          category.children.map((cc: any) => (
                            <div className="category-child-title">{cc.categoryName}</div>
                          ))}
                        {category.children &&
                          category.children.length > 3 &&
                          [0, 1, 2].map((index: any) => {
                            const cc = category.children[index]
                            return <div className="category-child-title">{cc.categoryName}</div>
                          })}
                        {category.children && category.children.length > 3 && (
                          <div className="category-child-title">...</div>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            {this.state.recommendList && this.state.recommendList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">精品推荐</div>
                  <Link to={'/app/home/reclist'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.recommendList.map((item: any, index: number) => (
                    <a href={item.recommendURL} target="_blank" title={item.recommendName}>
                      <div className="course-box mr-m">
                        <img className="img" src={IMAGE_SERVER + item.recommendDirectURL} alt="" />
                        <div
                          className="title"
                          title={item.recommendName}
                          style={{ textAlign: 'center' }}
                        >
                          {item.recommendName}
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
            {this.state.channelList && this.state.channelList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">视频直播</div>
                  <Link to={'/app/home/channelList'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.channelList.map((item: any, index: number) => {
                    let channelImg = item.banner
                    if (item.coverDirectURL) {
                      channelImg = IMAGE_SERVER + item.coverDirectURL
                    }
                    let statusDiv = <div></div>
                    if (item.liveStatus == 0) {
                      // 未开始
                      statusDiv = (
                        <div className="live-status unstart">
                          <img src={liveUnstartImg} alt="" />
                        </div>
                      )
                    } else if (item.liveStatus == 1) {
                      // 直播中
                      statusDiv = (
                        <div className="live-status started">
                          <img src={liveStartedImg} alt="" />
                        </div>
                      )
                    } else if (item.liveStatus == 2) {
                      // 已结束
                      statusDiv = (
                        <div className="live-status finished">
                          <img src={liveFinishedImg} alt="" />
                        </div>
                      )
                    }
                    const hasLoginDiv = (
                      <a
                        href={`${item.watchPCUrl}?token=${item.token}`}
                        target="_blank"
                        color="gray"
                        title={item.channelName}
                      >
                        <div className="course-box mr-m live" title={item.channelName}>
                          <img className={index == 0 ? 'big-img' : 'img'} src={channelImg} alt="" />
                          <div className="title">{item.channelName}</div>
                          {statusDiv}
                        </div>
                      </a>
                    )

                    const notLoginDiv = (
                      <Login
                        slot={
                          <div
                            className="course-box mr-m live"
                            title={item.channelName}
                            onClick={() => {}}
                          >
                            <img
                              className={index == 0 ? 'big-img' : 'img'}
                              src={channelImg}
                              alt=""
                            />
                            <div className="title">{item.channelName}</div>
                            {statusDiv}
                          </div>
                        }
                      />
                    )

                    return hasLogin() ? hasLoginDiv : notLoginDiv
                  })}
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
            {this.state.planList && this.state.planList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">热门计划</div>
                  <Link to={'/app/home/hotlist?type=plan'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.planList.map((item: any, index: number) => (
                    <Link to={'/app/train/plan?id=' + item.planId} color="gray">
                      <div className="course-box mr-m" title={item.title}>
                        <img
                          className={index == 0 ? 'big-img' : 'img'}
                          src={IMAGE_SERVER + item.coverDirectURL}
                          alt=""
                        />
                        <div className="title">{item.title}</div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
            {this.state.trainList && this.state.trainList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">热门培训</div>
                  <Link to={'/app/home/hotlist?type=train'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.trainList.map((item: any, index: number) => (
                    <Link to={'/app/train/train?id=' + item.itemId} color="gray">
                      <div className="course-box mr-m" title={item.title}>
                        <img
                          className={index == 0 ? 'big-img' : 'img'}
                          src={IMAGE_SERVER + item.coverDirectURL}
                          alt=""
                        />
                        <div className="title">{item.title}</div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="clear-fix"></div>
              </div>
            )}

            {this.state.courseList && this.state.courseList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">热门课程</div>
                  <Link to={'/app/home/hotlist?type=course'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.courseList.map((item: any) => (
                    <Link to={'/app/train/course?id=' + item.courseId} color="gray">
                      <div className="course-box mr-m" title={item.title}>
                        <img className="img" src={IMAGE_SERVER + item.coverDirectURL} alt="" />
                        <div className="title">{item.title}</div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
            {this.state.expertList.length > 0 && (
              <div className="home-hot-panel">
                <div className="flex-h-between panel-header">
                  <div className="title">专家简介</div>
                  <Link to={'/app/home/expertlist'} color="gray">
                    更多&gt;
                  </Link>
                </div>
                <div className="panel-body">
                  {this.state.expertList.map((expert: any) => (
                    <Card.Grid style={gridStyle}>
                      <Link to={'/app/home/expert?id=' + expert.expertId} color="gray">
                        <div className="teacher_box" title={expert.abstract}>
                          <div className="flex-hcenter header">
                            <Avatar className="img" src={IMAGE_SERVER + expert.photoDirectURL} />
                            <div className="flex-hcenter">
                              <div className="title">{expert.expertName}</div>
                              <div className="sub_title">{expert.job}</div>
                            </div>
                          </div>
                          <p className="detail">{expert.abstract}</p>
                        </div>
                      </Link>
                    </Card.Grid>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Skeleton>
      </div>
    )
  }
}

// 重新设置连接之后组件的关联类型
const DashboardConnect: React.ComponentClass<IDashboardProps, IDashboardStates> = connectAlita([
  { responsive: { isMobile: false } },
])(Dashboard)

export default withRouter(DashboardConnect)
